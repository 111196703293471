import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../template/Layout";
import style from "./Blog.module.scss";
import SEO from "../../template/seo";
import BreadCrumb from "../../template/BreadCrumb";

export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/blog/"}}, sort: {order: DESC, fields: frontmatter___date}) {
      edges {
        node {
          id
          frontmatter {
            slug
            date(formatString: "Do MMMM, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;

type PostLinkProps = {
  post: any;
}

const PostLink: React.FC<PostLinkProps> = ({ post }) => (
  <section className={style.blogLink}>
      <Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link>
      <div className={style.description}>{post.frontmatter.description}</div>
      <div className={style.date}>{post.frontmatter.date}</div>
  </section>
);

type RootProps = {
  data: any;
}

const Root: React.FC<RootProps> = ({ data }) => {
  
    const posts = data.allMarkdownRemark.edges.map(post => <PostLink key={post.node.id} post={post.node} />);

    return (
      <>
        <SEO 
          title="Blog Posts | Prime Draft"
          description="Blog posts about Prime Draft, distraction free writing, and the writing process"
        />
        <Layout contentStyle={style.layout} withBar={true}>
          <BreadCrumb links={[{ url:"/blog/", displayText: "Blog" }]} />
          <div className={style.linksBorder}>
            {posts}
          </div>
        </Layout>
      </>
    );
}

export default Root;

